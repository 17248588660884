.gas-play-hero {

    &--active {
        display: flex;
    }
}

.gas-play-countdown {

    &--active {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    &--meta {
        display: flex;
        padding-bottom: 0;
    }

    &__title {

        &--meta {
            margin-right: .25rem;
        }
    }
}