.gas-card-counter {
    
    &__team {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 -6px;

        .gas-toon-image {
            padding: 6px;
            max-width: 18%;
            flex: 0 0 18%;

            &:first-child {
                max-width: 28%;
                flex: 0 0 28%;
            }
        }

        &--fleet {            

            .gas-toon-image {
                max-width: 12%;
                flex: 0 0 12%;

                &:first-child {
                    max-width: 16%;
                    flex: 0 0 16%;
                }
            }
        }
    }

    &__actions {
        
        @media (min-width: 1400px) {
            flex-grow: 1;
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
    }
}

.gas-filters {
    align-items: center;
    flex-wrap: wrap;
    text-align: center;

    @media (min-width: 960px) {
        display: flex;
    }

    &__group {
        display: inline-flex;
        flex-wrap: wrap;
        margin-bottom: 24px;
        transition: background-color 300ms ease;
        border-radius: 4rem;

        &--click {
            cursor: pointer;

            &:hover,
            &:focus {
                background-color:rgba(144, 202, 249, 0.16);
            }
        }

        &--selected {
            background-color:rgba(144, 202, 249, 0.24);
        }

        &--nowrap {
            flex-wrap: nowrap;
            width: 100%;
            justify-content: center;
        }

        @media (min-width: 960px) {
            margin-right: 24px;
            flex-wrap: nowrap;
        }
    }

    &__toon {
        max-width: 76px;
        width: 76px;
        flex: 0 0 76px;
        padding: 6px;

        &--disabled {
            opacity: .2;
            pointer-events: none;
        }

        &--enabled {
            
            .gas-toon-image__frame {
                box-shadow: 0 0 1px 1px #ffa726;
            }

            .MuiSvgIcon-root {
                fill: #ffa726 !important;
            }
        }

        &--nowrap {
            width: 128px;
            max-width: 128px;
            flex: 1 0 0%;
        }
    }

    &__meta {
        max-width: 128px;
        width: auto;
        flex: 1;
        padding: 6px;
        display: flex;
        flex-direction: column;
        border-radius: 50%;
        background: rgba(206, 147, 216, 0.16);
    }

    &__banners,
    &__winrate  {
        display: flex;
        flex-direction: column;
        flex: 1;
        line-height: 1;
        justify-content: center;
    }

    &__banners  {
        font-weight: 700;
    }

    &__winrate  {
        font-size: .75rem;
        border-top: 1px solid rgba(255, 255, 255, 0.12);
    }
}