.gas-plan-header {
    background: #212121;

    &__content {
        display: flex;
    }
    
    &__details {
        flex-grow: 1;
    }

    &__menu {
        
    }
}

.gas-plan-map {
    overflow: hidden;
    padding: 32px 16px;    

    &__menu {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }

    &__squads {
        display: flex;
        margin-bottom: 16px;
        flex: 0 0 100%;
        max-width: 100%;

        @media (min-width: 900px) {
            flex: 0 0 auto;
            max-width: none;
            margin-bottom: 0;
        }

        button {

            @media (min-width: 900px) {
                white-space: nowrap;
            }

            &:first-child {
                margin-right: 4px;
            }

            &:last-child {
                margin-left: 4px;
            }
        }
    }

    &__zones {
        margin-top: 16px;
    }

    &__slider {
        width: 200%;
        max-width: 200%;
        transform: translateX(0);
        transition: transform 300ms ease;
        position: relative;

        @media (min-width: 900px) {
            width: 100%;
            max-width: 100%;
        }

        &--offense {
            transform: translateX(-50%);

            @media (min-width: 900px) {
                transform: translateX(0);
            }
        }
    }

    &__phase {

        @media (min-width: 900px) {
            display: none;
        }
    }

    &__defense {
        position: absolute;
        top: 0;
        left: 0;
        right: 50%;
        bottom: 0;
        display: flex;
        flex-wrap: wrap;
    }

    &__offense {
        position: absolute;
        top: 0;
        left: 51%;
        right: 0;
        bottom: 0;
        display: flex;
        flex-wrap: wrap;
    }
}

.gas-plan-zone {
    display: flex;
    flex-direction: column;

    &--defense-fleet {
        height: 47%;
        max-width: 41%;
        flex: 0 0 41%;
    }

    &--defense-north {
        height: 47%;
        max-width: 57%;
        flex: 0 0 57%;
        padding-left: 3%;        
    }

    &--defense-trap {
        height: 50%;
        max-width: 41%;
        flex: 0 0 41%;
        padding-top: 3%;
    }

    &--defense-south {
        height: 50%;
        max-width: 57%;
        flex: 0 0 57%;
        padding-left: 3%;  
        padding-top: 3%;        
    }

    &--offense-fleet {
        height: 47%;
        max-width: 41%;
        flex: 0 0 41%;
        padding-left: 3%;
    }

    &--offense-north {
        height: 47%;
        max-width: 56%;
        flex: 0 0 56%;        
    }

    &--offense-trap {
        height: 50%;
        max-width: 41%;
        flex: 0 0 41%;
        padding-left: 3%;
        padding-top: 3%;
    }

    &--offense-south {
        height: 50%;
        max-width: 56%;
        flex: 0 0 56%;  
        padding-top: 3%;        
    }

    &__header {
        display: flex;
        justify-content: space-between;

        &--defense-north {            
            padding-left: 40%;
            padding-right: 4%;
        }

        &--defense-fleet {            
            position: relative;
            padding-left: 6%;
            left: 16%;
        }

        &--defense-trap {              
            position: relative;
            padding-left: 6%;
            left: 16%;
        }

        &--defense-south {         
            padding-left: 40%;
            padding-right: 4%;
        }

        &--offense-north {            
            padding-right: 40%;
            padding-left: 4%;
        }

        &--offense-fleet {            
            position: relative;
            padding-right: 6%;
            right: 16%;
        }

        &--offense-trap {              
            position: relative;
            padding-right: 6%;
            right: 16%;
        }

        &--offense-south {         
            padding-right: 40%;
            padding-left: 4%;
        }
    }

    &__teams {
        flex-grow: 1;
        display: flex;
        align-items: flex-start;
        cursor: pointer;

        .gas-toon-image__frame {
            padding-bottom: 0;
            height: 3rem;
            width: 3rem;
            margin: .25rem;
        }
    }
}

.gas-plan-teams {
    display: flex;
    flex-wrap: wrap;
    flex-grow: 1;

    &--empty {
        height: 100%;
    }

    &--defense-north {       
        padding-right: 10%;

        .gas-plan-teams__edit {
            padding-right: 30%;
        }

        .gas-toon-image {

            &:nth-child(1) {
                margin-left: 1.75rem;
            }

            &:nth-child(3) {
                margin-left: .5rem;
            }
        }
    }

    &--defense-south {      
        padding-right: 40%;
    }

    &--offense-north {            
        padding-left: 40%;
    }

    &--offense-south {         
        padding-left: 40%;
    }

    &__edit {
        max-width: 100%;
        flex: 0 0 100%;
        align-items: center;
        justify-content: center;
        display: flex;
    }

    .gas-toon-image {
        width: 3rem;
        height: 3rem;
        border: 2px solid gray;
        border-radius: 50%;
        margin: .25rem;
    }
}

.gas-plan-drawer {
    height: 100vh;
    display: flex;
    flex-direction: column;

    &__header {
        display: flex;
        justify-content: space-between;
        padding: 16px;
    }

    &__teams {
        flex-grow: 1;
        padding: 16px;
        overflow-y: auto;
    }

    &__tabs {
        border-bottom: 1px solid #90caf9;
    }
    
}