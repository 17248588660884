.gas-hero {
    padding-bottom: 54%;
    background: url('images/GAS_logo_wide.png');
    background-size: cover;
    background-position: center;

    &--short {
        padding-bottom: 28%;
    }

    &--home {
        background-image: url('images/gas_hero.jpg');
        padding-bottom: 75%;

        @media (min-width: 600px) {
            padding-bottom: 28%;
            background: url('images/GAS_logo_wide.png');
            background-size: cover;
            background-position: center;
        }
    }
}

img {
    max-width: 100%;
    height: auto;
}