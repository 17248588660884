.gas-navigation {
    position: fixed;
    z-index: 1;
    right: 0;
    left: 0;
    top: 0;

    &__link {
        color: white;
        text-decoration: none;
        display: block;
    }
}

.gas-profile {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 24px;
    background-color: #121212;
}

.gas-hide-lg {
    
    @media (min-width: 900px) {
        display: none;
    }
}