.gas-dmg-unit {
    border: 1px solid #424242;
    border-radius: 8px 8px 0 0;
    padding-top: 16px;

    &__glance {
        padding-top: 8px;
        position: relative;
    }

    &__src {
        display: block;
        border-radius: 64px 64px 0 0;
        margin: 0 auto;
        max-width: 128px;
    }

    &__levels {
        border-radius: 0 0 8px 8px;
        padding: 8px 16px;
        background: #424242;
        display: flex;
        justify-content: space-between;
        margin: 0 auto;
        max-width: 128px;
        align-items: center;
    }
    
    &__level {
        display: flex;
        align-items: center;
    }

    &__mods {        
        display: flex;
        flex-wrap: wrap;
        margin: 24px auto;
        max-width: 216px;
    }

    &__stats {
        display: flex;
        justify-content: center;
        padding-right: 16px;
    }

    &__modifiers {
        position: absolute;
        bottom: 0;
        display: flex;
        flex-wrap: wrap;
        left: 0;
        right: 0;
    }
}

.gas-dmg-modifier {
    max-width: 50%;
    flex: 0 0 50%;
    padding: .375rem;

    &__src {
        width: 2.5rem;
        height: auto;
        display: block;
        overflow: hidden;
        border-radius: 50%;
    }

    &:nth-child(odd) {
        padding-right: 76px;

        img {
            margin-left: auto;
        }
    }

    &:nth-child(even) {
        padding-left: 72px;
    }
}

.gas-dmg-mod {
    background-repeat: no-repeat;
    background-size: 80px 80px;
    background-position: center;
    height: 96px;
    flex: 0 0 50%;
    max-width: 50%;
    padding: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: .75rem;
    position: relative;

    &--1 {
        background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxMzUuNjcgMTM2LjY3Ij48ZGVmcz48c3R5bGU+LmNscy0xe2ZpbGw6IzQyNDI0Mjt9PC9zdHlsZT48L2RlZnM+PGcgaWQ9IkxheWVyXzIiIGRhdGEtbmFtZT0iTGF5ZXIgMiI+PGcgaWQ9IkxheWVyXzItMiIgZGF0YS1uYW1lPSJMYXllciAyIj48cGF0aCBjbGFzcz0iY2xzLTEiIGQ9Ik0yMSwxMzUuNjdhMSwxLDAsMCwxLS43MS0uM2wtMTcuNS0xNy41YTEsMSwwLDAsMS0uMjktLjY5TDEsMkExLDEsMCwwLDEsMiwxSDEzMy42N2ExLDEsMCwwLDEsMSwxVjEzNC42N2ExLDEsMCwwLDEtMSwxWiIvPjxwYXRoIGNsYXNzPSJjbHMtMSIgZD0iTTEzMy42NywyVjEzNC42N0gyMUwzLjUsMTE3LjE3LDIsMkgxMzMuNjdtMC0ySDJBMiwyLDAsMCwwLC41OC42LDIsMiwwLDAsMCwwLDJMMS41LDExNy4xOWEyLDIsMCwwLDAsLjU5LDEuMzlsMTcuNSwxNy41YTIsMiwwLDAsMCwxLjQxLjU5SDEzMy42N2EyLDIsMCwwLDAsMi0yVjJhMiwyLDAsMCwwLTItMloiLz48L2c+PC9nPjwvc3ZnPg==);
    }

    &--2 {
	    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxMzkuMzMgMTM3Ij48ZGVmcz48c3R5bGU+LmNscy0xe2ZpbGw6IzQyNDI0Mjt9PC9zdHlsZT48L2RlZnM+PGcgaWQ9IkxheWVyXzIiIGRhdGEtbmFtZT0iTGF5ZXIgMiI+PGcgaWQ9IkxheWVyXzItMiIgZGF0YS1uYW1lPSJMYXllciAyIj48cGF0aCBjbGFzcz0iY2xzLTEiIGQ9Ik03Ny42NywxMzZhMSwxLDAsMCwxLS42OC0uMjdMNTQuNTEsMTE0Ljk0YTIyLjQ5LDIyLjQ5LDAsMCwxLTMzLTMwLjU0TDEuMzIsNjUuNzNBMSwxLDAsMCwxLDEsNjVhMS4wNSwxLjA1LDAsMCwxLC4yOS0uNzNMNDMuOTIsMjEuNjdsLTE5LTE5QTEsMSwwLDAsMSwyNS42NywxSDEzNy4zM2ExLDEsMCwwLDEsMSwxVjEwOWExLDEsMCwwLDEtLjYxLjkyLjkxLjkxLDAsMCwxLS4zOS4wOCwxLDEsMCwwLDEtLjctLjI5TDEyMC41LDkzLjU4LDc4LjM3LDEzNS43MUExLDEsMCwwLDEsNzcuNjcsMTM2WiIvPjxwYXRoIGNsYXNzPSJjbHMtMSIgZD0iTTEzNy4zMywyVjEwOUwxMjAuNSw5Mi4xNyw3Ny42NywxMzUsNTQuNDUsMTEzLjUyQTIxLjQ5LDIxLjQ5LDAsMSwxLDIyLjkyLDg0LjM1TDIsNjUsNDUuMzMsMjEuNjcsMjUuNjcsMkgxMzcuMzNtMC0ySDI1LjY3YTIsMiwwLDAsMC0xLjQyLDMuNDFMNDIuNSwyMS42Ny41OSw2My41OUEyLDIsMCwwLDAsMCw2NWEyLDIsMCwwLDAsLjY0LDEuNDNsMTkuNDYsMThhMjMuNDksMjMuNDksMCwwLDAsMzQuNDQsMzEuODZsMjEuNzcsMjAuMTRhMiwyLDAsMCwwLDIuNzctLjA2TDEyMC41LDk1bDE1LjQyLDE1LjQxYTIsMiwwLDAsMCwxLjQxLjU5LDIuMDcsMi4wNywwLDAsMCwuNzctLjE1LDIsMiwwLDAsMCwxLjIzLTEuODVWMmEyLDIsMCwwLDAtMi0yWiIvPjwvZz48L2c+PC9zdmc+);
        padding-top: 8px;
    }

    &--3 {	
        background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxMzYuNzYgMTM3LjY0Ij48ZGVmcz48c3R5bGU+LmNscy0xe2ZpbGw6IzQyNDI0Mjt9PC9zdHlsZT48L2RlZnM+PGcgaWQ9IkxheWVyXzIiIGRhdGEtbmFtZT0iTGF5ZXIgMiI+PGcgaWQ9IkxheWVyXzItMiIgZGF0YS1uYW1lPSJMYXllciAyIj48cGF0aCBjbGFzcz0iY2xzLTEiIGQ9Ik01MS40MywxMzYuNjRhMS4wNSwxLjA1LDAsMCwxLS43MS0uMjlMMS4yOSw4Ni45MkExLjA1LDEuMDUsMCwwLDEsMSw4Ni4yMVY1N2ExLDEsMCwwLDEsLjI3LS42OWw1Mi4yMS01NUExLDEsMCwwLDEsNTQuMjEsMUg4MC4zMmExLDEsMCwwLDEsLjcxLjI5bDU0LjQ0LDU0LjQ1YTEsMSwwLDAsMSwuMjkuN1Y4NmExLDEsMCwwLDEtLjI5LjcxTDEyMCwxMDIuMTlWMTIwYTEsMSwwLDAsMS0xLDFIOTQuOUw3OS41NSwxMzYuMzVhMSwxLDAsMCwxLS43LjI5WiIvPjxwYXRoIGNsYXNzPSJjbHMtMSIgZD0iTTgwLjMyLDJsNTQuNDQsNTQuNDRWODZMMTE5LDEwMS43OFYxMjBIOTQuNDlMNzguODUsMTM1LjY0SDUxLjQzTDIsODYuMjFWNTdMNTQuMjEsMkg4MC4zMm0wLTJINTQuMjFhMiwyLDAsMCwwLTEuNDUuNjJMLjU1LDU1LjYyQTIsMiwwLDAsMCwwLDU3Vjg2LjIxYTIsMiwwLDAsMCwuNTksMS40MUw1MCwxMzcuMDVhMiwyLDAsMCwwLDEuNDEuNTlINzguODVhMiwyLDAsMCwwLDEuNDEtLjU5bDE1LTE1LjA1SDExOWEyLDIsMCwwLDAsMi0yVjEwMi42MWwxNS4xOS0xNS4yYTIsMiwwLDAsMCwuNTgtMS40MVY1Ni40NGEyLDIsMCwwLDAtLjU4LTEuNDFMODEuNzMuNTlBMiwyLDAsMCwwLDgwLjMyLDBaIi8+PC9nPjwvZz48L3N2Zz4=);
    }

    &--4 {
        background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxMzggMTE3Ij48ZGVmcz48c3R5bGU+LmNscy0xe2ZpbGw6IzQyNDI0Mjt9PC9zdHlsZT48L2RlZnM+PGcgaWQ9IkxheWVyXzIiIGRhdGEtbmFtZT0iTGF5ZXIgMiI+PGcgaWQ9IkxheWVyXzItMiIgZGF0YS1uYW1lPSJMYXllciAyIj48cGF0aCBjbGFzcz0iY2xzLTEiIGQ9Ik0xMy4zMywxMTZhMSwxLDAsMCwxLS44NC0uNDdsLTExLjM0LTE4YTEsMSwwLDAsMSwwLTFsNTQuMzQtOTVhMSwxLDAsMCwxLC44Ni0uNWgyNGExLDEsMCwwLDEsLjg3LjQ5bDU1LjY2LDk1YTEsMSwwLDAsMSwwLC45NGwtOC42NywxOGExLDEsMCwwLDEtLjkuNTdaIi8+PHBhdGggY2xhc3M9ImNscy0xIiBkPSJNODAuMzMsMiwxMzYsOTdsLTguNjcsMThoLTExNEwyLDk3LDU2LjMzLDJoMjRtMC0yaC0yNEEyLDIsMCwwLDAsNTQuNiwxTC4yNiw5NmEyLDIsMCwwLDAsMCwyLjA2bDExLjMzLDE4YTIsMiwwLDAsMCwxLjY5LjkzaDExNGEyLDIsMCwwLDAsMS44MS0xLjEzbDguNjYtMThhMiwyLDAsMCwwLS4wNy0xLjg4TDgyLjA2LDFhMiwyLDAsMCwwLTEuNzMtMVoiLz48L2c+PC9nPjwvc3ZnPg==);
        padding-top: 24px;
    }

    &--5 {
        background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxNDEgMTQxIj48ZGVmcz48c3R5bGU+LmNscy0xe2ZpbGw6IzQyNDI0Mjt9PC9zdHlsZT48L2RlZnM+PGcgaWQ9IkxheWVyXzIiIGRhdGEtbmFtZT0iTGF5ZXIgMiI+PGcgaWQ9IkxheWVyXzItMiIgZGF0YS1uYW1lPSJMYXllciAyIj48cGF0aCBjbGFzcz0iY2xzLTEiIGQ9Ik03MC41LDE0MGE2OS41LDY5LjUsMCwwLDEsMC0xMzksNjguODIsNjguODIsMCwwLDEsMzAuNyw3LjE1QTE4LjQ4LDE4LjQ4LDAsMCwxLDEzMCwyMy41YTE4LjU1LDE4LjU1LDAsMCwxLTEuODksOC4xNEE2OS40OSw2OS40OSwwLDAsMSw3MC41LDE0MFoiLz48cGF0aCBjbGFzcz0iY2xzLTEiIGQ9Ik03MC41LDJhNjguMTIsNjguMTIsMCwwLDEsMzAuNzgsNy4zMUExNy40OSwxNy40OSwwLDAsMSwxMjcsMzEuNzEsNjguNDksNjguNDksMCwxLDEsNzAuNSwybTAtMmE3MC41MSw3MC41MSwwLDEsMCw1OC43NiwzMS41NUExOS41LDE5LjUsMCwwLDAsMTExLjUsNGExOS4yOCwxOS4yOCwwLDAsMC0xMC4zNywzQTY5Ljg3LDY5Ljg3LDAsMCwwLDcwLjUsMFoiLz48L2c+PC9nPjwvc3ZnPg==);
    }

    &--6 {
        background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxMzggMTM5LjY3Ij48ZGVmcz48c3R5bGU+LmNscy0xe2ZpbGw6IzQyNDI0Mjt9PC9zdHlsZT48L2RlZnM+PGcgaWQ9IkxheWVyXzIiIGRhdGEtbmFtZT0iTGF5ZXIgMiI+PGcgaWQ9IkxheWVyXzItMiIgZGF0YS1uYW1lPSJMYXllciAyIj48cGF0aCBjbGFzcz0iY2xzLTEiIGQ9Ik0zNCwxMzguNjdhMSwxLDAsMCwxLTEtMVYxMDVIMmExLDEsMCwwLDEtMS0xVjQwLjMzYTEsMSwwLDAsMSwxLTFIMzNWMmExLDEsMCwwLDEsMS0xaDcxLjY3YTEsMSwwLDAsMSwxLDFWMzdIMTM2YTEsMSwwLDAsMSwxLDF2NjUuNjdhMSwxLDAsMCwxLTEsMUgxMDZ2MzNhMSwxLDAsMCwxLTEsMVoiLz48cGF0aCBjbGFzcz0iY2xzLTEiIGQ9Ik0xMDUuNjcsMlYzOEgxMzZ2NjUuNjdIMTA1djM0SDM0VjEwNEgyVjQwLjMzSDM0VjJoNzEuNjdtMC0ySDM0YTIsMiwwLDAsMC0yLDJWMzguMzNIMmEyLDIsMCwwLDAtMiwyVjEwNGEyLDIsMCwwLDAsMiwySDMydjMxLjY3YTIsMiwwLDAsMCwyLDJoNzFhMiwyLDAsMCwwLDItMnYtMzJoMjlhMiwyLDAsMCwwLDItMlYzOGEyLDIsMCwwLDAtMi0ySDEwNy42N1YyYTIsMiwwLDAsMC0yLTJaIi8+PC9nPjwvZz48L3N2Zz4=);
    }

    &--change {
        cursor: pointer;
        transition: filter 200ms ease;

        &:hover {
            filter: sepia(1);
        }
    }

    &__stat {
        font-weight: 700;
        font-size: .875rem;
    }

    &__icon {
        background-repeat: no-repeat;
        background-position: center;
        height: 32px;
        width: 32px;
        position: absolute;
        top: 2px;
        left: 6px;

        &--1 {
            background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAcCAMAAAA3HE0QAAAAsVBMVEUAAAD///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////+3mHKcAAAAOnRSTlMAwxFxu+4t4aqWtA9VmTycCdv83QwSFVj9AZqbUeqdrXMZK+8G5vg3XYoc1QVLBPL+A7F58OlJKIGmAxRd1AAAAK9JREFUeNrFzAN6xGAAhOFZI05q23bn/gfr39jGuxvO9wSeBR0rxA0SPI2FDdi20wKVwhq2raIAtYL3SDBz7fjBl8lPy9LgYszq+4NvOoSM4Neg9grfZiJ44Q9CxmDc89RnJ8xRLlgGjk1ydhgOYm5J7tvLCGluSE7m2cH1ldiRHdxd/u8Zga4oyr29J4Jd+yxRsPeML9jBAhWCvfzAOEDMgyw8usH5yQVynB2dIuIPg/5MO7InDUwAAAAASUVORK5CYII=);
        }

        &--2 {
            background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB0AAAAdCAMAAABhTZc9AAABdFBMVEUAAAD////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////wdlxEAAAAe3RSTlMAYf7wywX7Kw/KAf0drt1YthoCFene+RI0CEv3LLymRRtMlXCgGIJi7Pjjx+ow0CG9Uw15jUOa0hEKUFKLuJ6S8YAQtWAZFPaJ8/V9b0KrJjkXwx7l4FnaKi180Zc+Og6E5nNaJDjboXU8Btk3KQtmp042mLSot6/NZAcix11fAAABPUlEQVR42nTM47okQQCD4Yxtc217j23bts3c/KnqHk/1+/d7EhR8cUDnRpHXhqJHfyF3QBd+NYSyu6iW0/FOSPOf5lIoe6CWrTQBsC1+MK+gQsgsc5rsApJL5DKkwEUEmnvys8NKxnv67eTHNUiRXR69PsuG8ZbkIIV2Ci9kCgHejxQstzes4Ft9lfe/fwPg5DPVApAuLVTxQTeSUUTtV+r9a/Sb+NH2z2z4+7/VTJXfXmj++KniyXX/hNDQbKfKwDA0ya+KODoFndPFWjPPYByZeWccpZc2wyhNTohopcJsYmz8xTO43+Zfrm++u2a116hwuMGiGKVpN8q2KPRZZUyZLBQWUPaJtF+FvnnIRuB5kOR+OR7Tc74NoIXfIYl5rlz3gl8gNdmzkMT8aZp5MEmhVCiD1RiugTETSAAAjeTVpyZe1pUAAAAASUVORK5CYII=);
        }

        &--3 {
            background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAZCAMAAAACPC7cAAAAmVBMVEUAAAD////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////VHQRUAAAAMnRSTlMAzNvf/CvrDgLGUWo49MtTUgT6+VW3I4Sd9v2U87yqC6ZcWSw6jo/FxzkcMB0v7oLvg+xj+BoAAACfSURBVHjabchVQsNQFEDBE0/dvY3izt3/3nCez+egPG82j7ge9keR4/7JymJ3kB+HXaFyMcpFyUeL3x3GYomHwPhWPIMxkQREXEJ9oQl1w2uo3+lC3dGHuqd88fethMrvCmj9boH67O65Bji5feLbdmnvcsuPa7tv+FUk5iYFf+5XoqyuUObp/6ZzDLPJ705mWLLp904zHOV6cLcu+fMJ+11GprvpHGIAAAAASUVORK5CYII=);
        }

        &--4 {
            background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB8AAAAdCAMAAABluEcAAAACK1BMVEUAAAD///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////9U+LSLAAAAuHRSTlMACvdL/gQBEgP48uIOLO8MX7apIKLd9iOggaeOUewLAvuYrC156+YXyKVxVDvN5Sk/z2vQBvx7Z51dLjmJ6CvuPFDMgpJP2Zy7v2YWejFA27FEHvNDwele4Lgf05ZqIRWHfRNOqDJW9MN0GYg+YHWm+lnRc3aVqoOfRSZXY8CyYrm+44WkYd6Xnpqr/UhS5Nz5lCVu17MnhGTKt/F3G7UJ35F/BXi9StbtKtShyzoiHdrhxq5MEAgveBHTIgAAAeNJREFUeNp8zoO6HGEMgOGsbds8tm3bqG3btm3byuU1g3ZRvA8G34/A/8lTr0/UsXnN6MTTR86x44crbcCSAJHlViVr7cGxF+NW/CUeS/XFhXvDLuOzt69fYsqVVGf5BNPv3r8SdYdvcT3A95QWz5vnCwDOcb0XwKRLm09QE6W/7a1nh/cjsVwHzeSgyAfe6raK8sBpQ9dDZPn3VUYbBq41AtgRj0hBEGqq6D11O067R66eQVImPH+pHqBfgRdMUuBIvQlEx4Omkwok4ouRoHuKfsBvto/4iYbTKDFFtTvVv4eSXwEgwQ+BDBdkkA//mOO2a/ccOGTFhno+6KrdydkvnycVtI1lpsV9tS3Ak43OPTZPxWdU/OUrCuEvvn3oG+T6Ujn81XQdm4W5fyaft2deww9vzEyy/pvhWWfnzLiQy9qarD40cbfjfvNARMx1A2QbugegjrV5+P32gsx8OXLHFQK4QfuVViSeVbL0foJ+jQT0FsSN3TnNyFgpghTRUWYy5vYNpbB6fT4ShXNZakHpMRU7m8oGpKCjDEndInVqhbrqIB2vB86uTgWSvCWQpqd8TepjUx6ShZBBsNatL96yY9vO7UHzZspFsvQq1Zkac9YVb3W0Gg1dRSUl7fnLfw4kDACwPd1KHvDCqgAAAABJRU5ErkJggg==);
        }

        &--5 {
            background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB0AAAAdCAMAAABhTZc9AAABqlBMVEUAAAD////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////eFPMCAAAAjXRSTlMABfv+yg8Cx3wrYcvwEmKxAR39rt1YthUa6erlcKb1B/lLRbw0GwhMLJWgGOz3+OMw3oIXvQq1Qg4hZBCnFG6TjbiSi1MN0lIRUEOeefFggBmJ831vOcNZ4B6rJiot2tE6lz6YJHXZODbbZk4pCzwGWnOE5jehmva0r8230JAxR6jAL3bCST0iKNZKh1SRxHjFAAABnUlEQVR42mKAAgNAl/PYNkkMhFH4GXa/tm3bnLVt27Ztnf+8SevK7v31JFVVpFClYlUpxTpqo7xfoXzrYSX0sinIXZ0rss545/qdukyQSyiWlPLHM5fkaMzY3AXzUu95uCir7naDAq+guagEOg8cTMPEoqyGm1wbvjWY1zTQjrEXY8CmRqlqAqP09QccNRda22pHRyTdaSbgGbjqZN0oDWK51OrmGoUWxoAfMnwSZm5o7RmQ17qvP//NLduyaVsGo0wVBZXz/TPvZpK5O3abZlVUVPj6zTcV9BbYUKXArlpC7R0dHl8ktWBU9+zbKmNucxrj5y+1AUtaIHToiAK9b4Cs1tqBZbUQOHVSoVwWvBVlMaRFjBPdciK+vq56HvP6pI/AWLkbvTIZvpefa9Y61lAqicwqqL583ovAsRkTS7C8BNbxstNHB7pVOd029Ohh+Qv+NSzH/cfEprBaKp36AON5iY39xaUYZ53qQfpy41I17JT66oGrSjyhevWKpI3R6eZ7jxJP6ydlbU8PyrDfx+8qkr+uUGqPYsX3JP0FAKq9luhenJkAAAAASUVORK5CYII=);
        }

        &--6 {
            background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB0AAAAdCAMAAABhTZc9AAABhlBMVEUAAAD///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////+X02G5AAAAgXRSTlMAycoFiysP35mx/vvL8GEBHf2u3bZYAhoV6TQS97xFpnCVCEygghhiLBvs4/j5x0vqMI3QUxGeCkPSmg2SYFJ5FNWJb/N9QlmrwyaAEDkeF+XgKnzR2i24UA4hwvH2lz69hN46tXPbdfULTlqYOCQZKWY2N9kGPLTmoaeot2QHza/Phf5gAAABWklEQVR42m3Q45olMRSF4dV9bNu2xrZt27a97nySqpOn9uD9+4UbSwsPbDEYcReMH6llPgpbtr4fjl8FK88mx6CdmZ5vivqdVg4zCsB1dhy8CSEX1HlG3gOK58gb0DJP8rD8JOeeMDl5uSdEji5Dyz/gu/brfhYdko+oXKPS0ikHxEdUIt++kvRpVJJ367XUsAvg1ZyGG4CfRgbam4isAS4lYTtQps0vqj7XsmuLrPLc0rptm4MUdUWeu36DaUrAqWvisGxK/a8yUdm+FcpGd0jU3TT27YWluJaWFQA+Ew+dgM27+k89XQVklJMkyz0ZZbU1XCb+d86HT6oY5n/qwdKR460qYp1a4/b93se/ahvC+080916ldiom6lMq1/Udd5rRCJULok7J0LPclQR5CRikST504lsmvrwAcJG3oKntFad+Ti+g7Qj1oejt48cmZp/D5toJI/oBwG/NzrkTksr79wAAAABJRU5ErkJggg==);
        }

        &--7 {
            background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAMAAABEpIrGAAAAn1BMVEUAAAD///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////8Kd3m4AAAANHRSTlMAZbsJSrNr5knn1+3k05EGERLv8Hn+QA/uesAgDZCyama9OggcyuMkiIl45djWDtI7ITnBvZeZ9QAAAPdJREFUeNrF0wVyAkEQBdCPrLvgJDjE9d//bJlqtDfUlEUesuPaC833PB82HunBhsZvNshnDg1nluOaVV3yoBx18M0kIRdzGvMFmUzQsN4y7Ms2A6AfcruGstrw8wVAN4i7AKZjbvQsD3wa4MIgZI0LRdV7g7LsVQXOXLbR0KaLs1u25Jml0TDNJNmic7w/CrnFGxp3ulRSkgYQ0YiAi9JzMrjaIDA7p5D9yxT3gC5VixzqRdq3qQ9qCeVDHxRqPluPGp1XjqfHqJbLSjpQdo/H6/bkuuMdGt4Tst2i0WrrgDnqjGwhJ3J3H7Ru/n8vDo5RbSFRrXwBwyctUzuNZ9cAAAAASUVORK5CYII=);
        }

        &--8 {
            background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABUAAAAcCAMAAAC5xgRsAAABzlBMVEUAAAD///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////9YkN6lAAAAmXRSTlMA6vbw6/jv7ezgCu7VBfz6K/T98r7+9x/10XOMLdzxsTxaV975RpDktx5d1AZbfL8hONlJMJ6NFCApAghm49MWuc/n36CFWbVlrqIy2lTLn0Mccn8a+5lpm7akhsWjaE2D0kemCVDlAT2szlyqeKWLd0AvD9jhk2N6McMqDMI/uHFTNVi6B5dhqSOdEhkYdsdWlCetG82wYkwpmZi2AAABc0lEQVR42mXKg5JkUQAE0Xq+bffYtmdt27Zt27Y3v3bsPqGKjNKYmq7GMyoQhYpzx5Ka5SQZalpIb12raWrGjrdfwWPnjurpehEuqy4Xt3SKC+c1ZvdpdTT13cDnvjyo0pgDZy9J6gTCHTg01D7sl7o9aLreog9dfxVNkw37PJGOsgfIf/ws/SfFy6fwTDrC4RNk6mFAv4i36TW8UKyOg7oGkPv5B75/gl6pGpt9Sr77+q8/9vuH+fIenkuHMOzSLU0a9OPtUhtBonU/jWG1DvZ1a8TjRz3aS8Ry8bjzKvyNfG/27r0HzT2KbcayfePCwJu3jLktqRzPDYLAraTzJn4QRLiqEaU4wSjKGkaXy3GNyjJ2Ztv2kWp8tmhUEZ4xBmrLqbcMy2MaU4Zx7A2bpNJVKZYs0rh5kUTxyjXrN65bXZEmqUlRjMWoFIs1aeFSgnHFufmaUjJZPXvBdF0xWRORoukaZVJ+2XStqgyNy5SENWIYsah2nFYNkd4AAAAASUVORK5CYII=);
        }
    }
}

.gas-dmg-stats {
    margin-bottom: 8px;

    &__item {
        margin-bottom: 8px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
    }

    &__label {
        font-weight: 700;
        font-size: .75rem;
        text-align: right;
        padding-right: 16px;
        flex-grow: 1;
    }
}

.gas-dmg-results {
    border-radius: 0 0 8px 8px;
    padding: 16px 16px 8px 16px;
    background: #424242;

    &__item {
        padding-bottom: 12px;
        padding-top: 8px;
    }

    &__label {
        font-size: .75rem;
        font-weight: 700;
        margin-bottom: 8px;
    }

}