.gas-list {
    
    @media (min-width: 768px) {
        display: flex;
        flex-wrap: wrap;
    }

    &__item {        
    
        @media (min-width: 768px) {
            max-width: 50%;
            flex: 0 0 50%;
        }
    }
    
}