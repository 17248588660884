.news {

    &__item {
        display: flex;
        cursor: pointer;
        transition: opacity 200ms ease;
        align-items: center;

        &:hover,
        &:focus {
            opacity: .8;

            .news__link {
                transform: scale(1.25);

                @media (min-width: 768px) {
                    transform: scale(2);
                }
            }
        }
    }

    &__content {
        padding: 0 1rem;
        font-size: 1rem;

        @media (min-width: 768px) {
            font-size: 1.5rem;
            padding: 0 1.5rem;
        }
    }

    &__link {
        flex: 0 0 3rem;
        max-width: 3rem;
        height: 3rem;
        border-radius: 50%;
        background: rgba(0,0,0,.5);
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        transition: transform 200ms ease;
    }

    .news-date {
        flex: 0 0 4rem;
        max-width: 4rem;

        @media (min-width: 768px) {
            flex: 0 0 6rem;
            max-width: 6rem;
        }
    
        &__icon {
            width: 4rem;
            height: 4rem;
            border-radius: 50%;
            background: white;
            color: #303030;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;

            @media (min-width: 768px) {
                width: 6rem;
                height: 6rem;
            }
        }
    
        &__day {
            font-size: 1.5rem;
            line-height: 1;

            @media (min-width: 768px) {
                font-size: 2rem;
            }
        }
    
        &__month {
            font-size: .75rem;
            font-weight: 700;
            text-transform: uppercase;

            @media (min-width: 768px) {
                font-size: .875rem;
            }
        }
    }
}

.gas-page {
    border-top: 3.5rem solid #90caf9;
    
    @media (min-width: 600px) {
        border-top-width: 4rem;
    }
}