.gas-compare-profile {
    display: flex;
    align-items: center;
    flex-direction: column;
}

.gas-compare-avatar {
    width: 4rem;
    height: 4rem;
    font-size: 2rem;
}

.gas-compare-summary {
    margin-top: 1rem;

    &__grid {
        padding-bottom: .75rem;
        align-items: center;
    }

    &__left {
        text-align: right;
    }

    &__right {
        text-align: left;
    }
}

.gas-compare-bracket {
    display: none;

    @media (min-width: 900px) {
        display: block;
    }
}