.gas-history-team {
    margin-bottom: 24px;

    &__meta {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin-bottom: 16px;
    }

    &__battle {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        @media (min-width: 900px) {
            flex-direction: row;
        }
    }

    &__toons {
        display: flex;
        margin: 0 -8px;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        width: 100%;

        @media (min-width: 900px) {
            width: auto;
            flex: 0 0 45%;
            max-width: 45%;
            justify-content: flex-start;
            padding: 0 16px;
        }

        @media (min-width: 1200px) {
            flex: 0 0 46%;
            max-width: 46%;
        }

        .gas-toon-image {
            flex: 0 0 19%;
            max-width: 19%;
            padding: 4px;

            @media (min-width: 600px) {
                flex: 0 0 12%;
                max-width: 12%;
            }

            @media (min-width: 900px) {
                flex: 0 0 19%;
                max-width: 19%;
            }

            @media (min-width: 1200px) {
                flex: 0 0 12%;
                max-width: 12%;
            }

            &:first-child {
                flex: 0 0 24%;
                max-width: 24%;

                @media (min-width: 600px) {
                    flex: 0 0 16%;
                    max-width: 16%;
                }

                @media (min-width: 900px) {
                    flex: 0 0 24%;
                    max-width: 24%;
                }

                @media (min-width: 1200px) {
                    flex: 0 0 16%;
                    max-width: 16%;
                }
            }
        }

        &--attack {

            @media (min-width: 900px) {
                flex-direction: row-reverse;
            }
        }

        &--fleet {

            &:before {
                content: '';
                display: block;
                flex: 0 0 2px;
                max-width: 2px;

                @media (min-width: 600px) {
                    display: none;
                }

                @media (min-width: 900px) {
                    display: block;
                }

                @media (min-width: 1200px) {
                    display: none;
                }
            }
        }
    }

    &__status {
        text-align: center;
        margin-bottom: 8px;
        flex: 0 0 100%;
        max-width: 100%;
    }

    &__end {
        text-align: center;
        flex: 0 0 100%;
        max-width: 100%;
    }

    &__vs {
        width: 50%;
        margin: 16px 0;

        @media (min-width: 900px) {
            flex: 0 0 10%;
            max-width: 10%;
            width: auto;
        }

        @media (min-width: 1200px) {
            flex: 0 0 8%;
            max-width: 8%;
        }
    }
}

.gas-history-rounds {

    &__select {

        @media (min-width: 900px) {
            height: 56px;
        }

        button {
            word-break: break-word;
            text-transform: none;
        }
    }

    &__alert {

        @media (min-width: 900px) {
            height: 56px;
            align-items: center;

            svg {
                transform: rotate(-90deg);
            }
        }
    }
}

.gas-histor-versus {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 16px;

    @media (min-width: 600px) {
        flex-direction: row;
    }

    &__tag {
        display: flex;
        align-items: center;
        
        @media (min-width: 600px) {
            padding: 0 12px;
        }
    }

    &__icon {
        margin-right: 4px;
    }
}