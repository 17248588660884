.gas-toon-image {
    max-width: 128px;
    position: relative;

    &__container {        
        position: relative;
    }

    &__src {
        display: block;
        max-width: 100%;
        height: auto;
        border-radius: 50%;

        &--selected {
            outline: 1px solid #90caf9;
        }
    }

    &__frame {
        box-shadow: 0 0 1px 1px grey;
        border-radius: 50%;
        padding-bottom: 100%;
        position: relative;
    }

    &__add {
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
        display: flex;
        justify-content: center;
        align-items: center;

        button {
            width: 100%;
            height: 100%;
        }
    }

    &__remove {
        position: absolute;
        top: 0;
        right: 0;
    }

    &__selected {
        position: absolute;
        top: 50%;
        left: 50%;
        margin-left: -1rem;
        margin-top: -1rem;
    }

    &__complete {
        padding: 0 24px 24px 24px;
    }

    &__close {
        position: absolute !important;
        top: 8px;
        right: 8px;
    }

    &__stats {
        display: flex;
        position: relative;
        margin: -14px -8px 0 -8px;
        flex-wrap: wrap;
        justify-content: center;
        
        @media (min-width: 600px) {
            position: absolute;
            left: 0;
            right: 0;
            justify-content: space-between;
            bottom: 14px;
        }
    }

    &__gear,
    &__relic {
        font-size: 12px;
        border-radius: 24px;
        display: block;
        margin-bottom: 4px;
        padding: 0 4px;
        height: 24px;
        line-height: 24px;
        margin: 2px 4px;
        
        @media (min-width: 600px) {
            font-size: 14px;
            height: 36px;
            line-height: 36px;
            padding: 0 8px;
        }
    }

    &__gear {
        background-color: #9240fc;

    }

    &__relic {
        background-color: #830f0f;
    }

    &__omicron,
    &__zeta {
        margin: 2px 4px;
    }

    &__stat-icon {
        width: 24px;
        height: 24px;
        position: absolute;
        z-index: 1;

        @media (min-width: 600px) {
            width: 36px;
            height: 36px;
        }
    }

    &__stat-label {
        position: relative;
        z-index: 2;
        width: 24px;
        height: 24px;
        line-height: 24px;
        font-size: 12px;

        @media (min-width: 600px) {
            width: 36px;
            height: 36px;
            line-height: 36px;
            font-size: 14px;
        }
    }

    &__speed {
        display: flex;
        align-items: center;
        font-size: 12px;
        justify-content: center;
        margin: 4px 0 -14px 0;
        
        @media (min-width: 600px) {
            padding: 0 6px 0 6px;
            font-size: 14px;
            background-color: #997a1e;
            border-radius: 24px;
            position: absolute;
            bottom: 0;
            transform: translate(50%,-4px);
        }

        &:before {
            content: '';
            background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB8AAAAdCAMAAABluEcAAAACK1BMVEUAAAD///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////9U+LSLAAAAuHRSTlMACvdL/gQBEgP48uIOLO8MX7apIKLd9iOggaeOUewLAvuYrC156+YXyKVxVDvN5Sk/z2vQBvx7Z51dLjmJ6CvuPFDMgpJP2Zy7v2YWejFA27FEHvNDwele4Lgf05ZqIRWHfRNOqDJW9MN0GYg+YHWm+lnRc3aVqoOfRSZXY8CyYrm+44WkYd6Xnpqr/UhS5Nz5lCVu17MnhGTKt/F3G7UJ35F/BXi9StbtKtShyzoiHdrhxq5MEAgveBHTIgAAAeNJREFUeNp8zoO6HGEMgOGsbds8tm3bqG3btm3byuU1g3ZRvA8G34/A/8lTr0/UsXnN6MTTR86x44crbcCSAJHlViVr7cGxF+NW/CUeS/XFhXvDLuOzt69fYsqVVGf5BNPv3r8SdYdvcT3A95QWz5vnCwDOcb0XwKRLm09QE6W/7a1nh/cjsVwHzeSgyAfe6raK8sBpQ9dDZPn3VUYbBq41AtgRj0hBEGqq6D11O067R66eQVImPH+pHqBfgRdMUuBIvQlEx4Omkwok4ouRoHuKfsBvto/4iYbTKDFFtTvVv4eSXwEgwQ+BDBdkkA//mOO2a/ccOGTFhno+6KrdydkvnycVtI1lpsV9tS3Ak43OPTZPxWdU/OUrCuEvvn3oG+T6Ujn81XQdm4W5fyaft2deww9vzEyy/pvhWWfnzLiQy9qarD40cbfjfvNARMx1A2QbugegjrV5+P32gsx8OXLHFQK4QfuVViSeVbL0foJ+jQT0FsSN3TnNyFgpghTRUWYy5vYNpbB6fT4ShXNZakHpMRU7m8oGpKCjDEndInVqhbrqIB2vB86uTgWSvCWQpqd8TepjUx6ShZBBsNatL96yY9vO7UHzZspFsvQq1Zkac9YVb3W0Gg1dRSUl7fnLfw4kDACwPd1KHvDCqgAAAABJRU5ErkJggg==);
            display: block;
            width: 16px;
            height: 16px;
            background-size: contain;
            background-repeat: no-repeat;
            margin-right: 4px;
        }
    }
}